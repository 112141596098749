import React from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import OpenGraph from './open-graph';
import TwitterTags from './twitter-tags';

function SEO({ title, description, lang,  meta: customMeta, image: metaImage }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            lang
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{ lang }}
        title={metaTitle}
        meta={[
          { name: 'MobileOptimized', content: "320" },
          { name: 'HandheldFriendly', content: "true" },
          { name: 'google', content: "notranslate" },
          { name: 'referrer', content: "no-referrer-when-downgrade" },
          { name: `description`, content: metaDescription },
        ].concat(customMeta)
      }
      />

      <OpenGraph
        siteUrl={site.siteMetadata.siteUrl}
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
      />

      <TwitterTags
        // siteAuthor="@_TreeTree2" // Uncomment when twitter account for afterschool is created
        siteUrl={site.siteMetadata.siteUrl}
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
      />
    </React.Fragment>
  );
}

SEO.defaultProps = {
  title: '',
  lang: `pt`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    mediaType: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default SEO;